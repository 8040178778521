import { AsyncStorageKeys, Pages } from "../../Types";
import { globalAny, historyFormat } from "../../utils/Utils";
import ridevueLogo from "../../assets/Logo/iconLogo.svg";
import backIcon from "../../assets/TopNavBarIcon/icon_nav_back.svg";
import TopAppbar from "../../components/TopAppBar";
import { useEffect, useState } from "react";
import { FlatList, ScrollView, TouchableOpacity, View } from "react-native";
import styled from "styled-components/native";
import { light_black, spacing_s } from "../../StyleHelpers";
import { useNavigation } from "@react-navigation/native";
import * as CacheStorageUtils from "../../utils/CacheStorageUtils";
import parse from "html-react-parser";
import { purchaseHistory } from "../../services/paymentService";
import { responseOk } from "../../utils/ValidateResponse";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import LabelContainer from "../../components/SettingsComponent/labelContainer";
import Separator from "../../components/Separator";
import useGenericContentStore from "../../store/genericContent.store";
import { routeTitleDetails, TitleDetailsScreenNavigationProp } from "../../Routes";

const StyledContainer = styled.View`
	height: 100vh;
	z-index: -1;
	padding-top: 180px;
`;

const StyledOverflow = styled.View`
	height: 100%;
	overflow: scroll;
	padding-left: 20px;
	padding-right: 20px;
`;

const StyledText = styled.Text`
	font-size: 25px;
	color: white;
	margin-bottom: 100px;
`;

const StyledWrapper = styled.View`
	z-index: -1;
	padding: 0 14.1% 0 14.1%;
`;

const LegalsScreen = (props: any) => {
	const navigation = useNavigation<any>();
	const navigationTitleScreen = useNavigation<TitleDetailsScreenNavigationProp>();
	const [title, setTitle] = useState("");
	const [body, setBody] = useState("");
	const [transactionHistory, setTransactionHistory] = useState();
	const setNavigateEvent = useGenericContentStore((state: any) => state.setNavigateEvent);
	const { route } = props;
	const { location } = route.params;

	const onBackPress = () => {
		if (navigation.canGoBack()) {
			navigation.goBack();
			return;
		}
		window.location.assign("/");
	};

	const onPress = (parentProductId: number) => {
		setNavigateEvent(Pages.transactions);
		navigationTitleScreen.navigate(routeTitleDetails, { parentProductId: parentProductId });
	}

	const renderItem = ({ item }: any) => (
		<TouchableOpacity style={{ marginBottom: spacing_s }} onPress={() => onPress(item.parentProductId)}>
			<View style={{ marginBottom: 7 }}>
				<LabelContainer addMarginChild={true} header={item.title} body={item.details}></LabelContainer>
			</View>
			<Separator type={ComponentTypeEnum.Secondary}></Separator>
		</TouchableOpacity>
	);

	
	useEffect(() => {
		(async () => {
			switch (location) {
				case Pages.legalTerms:
					const resultLegal = await CacheStorageUtils.getCacheItem(AsyncStorageKeys.legals, true);
					const data = resultLegal?.legalContents?.filter((val: any) => val?.type === 3)[0].htmlContent;
					setTitle(globalAny.language.terms_of_service);
					setBody(data);
					return;
				case Pages.transactions:
					const transactionHistory: any = await purchaseHistory();
					if (transactionHistory.responseCode === responseOk) {
						setTransactionHistory(historyFormat(transactionHistory?.purchases));
						setTitle(globalAny.language.transaction_history);
					}
					return;
				default:
					return;
			}
		})();
	}, []);

	return (
		<View style={{ zIndex: -1, backgroundColor: light_black }}>
			<TopAppbar
				leftIconWidth={92}
				leftTitleIcon={backIcon}
				screenTitle={title}
				rightLogo={ridevueLogo}
				onPressLeftTitleIcon={onBackPress}
				isSingleLogo={true}
			/>
			{/* @ts-ignore */}
			<StyledWrapper>
				{/* @ts-ignore */}
				<StyledContainer>
					{/* @ts-ignore */}
					<StyledOverflow>
						{/* @ts-ignore */}
						<StyledText>{parse(body)}</StyledText>
						{transactionHistory ? (
							<ScrollView style={{ marginBottom: 30 }}>
								<FlatList
									keyExtractor={(item) => item.parentProductId}
									data={transactionHistory}
									extraData={transactionHistory}
									renderItem={renderItem}
									showsVerticalScrollIndicator={false}
									showsHorizontalScrollIndicator={false}
								/>
							</ScrollView>
						) : (
							<></>
						)}
					</StyledOverflow>
				</StyledContainer>
			</StyledWrapper>
		</View>
	);
};

export default LegalsScreen;
