import { Language } from "../Types";

/** 
  All strings to be displayed for English users
 */
export const EN: Language = {
	Strings: {
		header_all: "All",
		stop_all: "Stop All",
		header_drive: "Drive",
		header_park: "Park",
		header_home: "Home",
		header_screen_manager: "Screen Manager",
		header_trip_assist: "Trip Assist",
		header_my_library: "My Library",
		header_settings: "Settings",
		movies_to_redeem: "Movies to Redeem",
		unlimited_streaming: "Unlimited Streaming",
		catch_up_before_the_new_movie: "Catch Up Before the New Movie",
		content_under_15_minutes: "Content under 15 minutes",
		popular_search: "Popular Searches",
		no_matches_found: "No matches found",
		play: "Play",
		playing: "Playing",
		paused: "Paused",
		browsing: "Browsing",
		see_more: "See More",
		redeem: "Redeem",
		select_screens: "Select Screens",
		back: "Back",
		top: "Top",
		bottom: "Bottom",
		details_unavailable: "Details unavailable",
		four_k: "4K",
		director: "Director",
		cast_and_crew: "Cast & Crew",
		movie_not_available: "This movie is not available. You may need to redeem it.",
		hd: "HD",
		playback_error: "Playback Error",
		please_try_again: "Please try again",
		please_try_again_after: "Please try again after 30 seconds...",
		next: "Next",
		create_account: "Create Account",
		forgot_email: "Forgot Email",
		forgot_password: "Forgot Password",
		ok: "Ok",
		agree: "I Agree",
		cancel: "Cancel",
		sign_in: "Sign In",
		profileName: "ProfileName",
		password: "Password",
		email: "Email",
		invalid_credentials: "Invalid Email or Password.",
		invalid_email: "Please enter a valid Email",
		invalid_password: "Please enter a valid Password",
		invalid_birthday: "Please enter a valid birthday",
		invalid_temporary_password: "Invalid temporary password",
		invalid_current_password: "Invalid current password",
		fill_up_current_password: "Please fill up current password",
		password_invalid: "The password is invalid",
		logout: "Logout",
		trailer: "Trailer",
		do_you_redeem: "Do you want to redeem",
		available_audio_languages: "Available Audio Languages:",
		available_closed_caption_languages: "Available Closed Caption Languages:",
		main_user: "Front Screen",
		profile_name_taken: "Profile Name taken",
		profile_name_taken_body: "Please enter a different profile name",
		left_rear_user: "Left Rear",
		right_rear_user: "Right Rear",
		add_profile: "Add Profile",
		who_is_watching: "Who is Watching?",
		all_devices_paused: "All devices are paused.",
		enter_name: "Enter Name",
		enter_email: "Enter Email",
		enter_email_ca: "Enter your email address",
		enter_password_ca: "Enter a new password",
		confirm_password: "Enter a new password again",
		password_rules: "Password must be at least 6 characters long.",
		enter_birthday: "Enter your date of birth",
		new_profile: "New Profile",
		search: "Search",
		unlimited_viewing: "Unlimited Viewing",
		studio_access: "Studio Access",
		feature_disabled: "This feature is not active in this demo.",
		swipe_to_see_featured: "Swipe to see featured titles",
		playlist_unavailable: "This Playlist is not available.",
		extras: "Extras",
		network_connection_lost: "Network connection lost. Please try again.",
		error_code: "Error Code: ",
		client_disconnect: "Device disconnected. Check your internet connection status.",
		select_a_device: "Please select at least one device.",
		channel: "Channel: ",
		pause: "Pause",
		restart: "Restart",
		theater: "Theater ",
		no_content_streaming: "No content currently streaming",
		start_playback_to_manage: "Start streaming to manage screens.",
		pass_rules: "Password must be at least 6 characters long.",
		passwords_dont_match: "Passwords do not match",
		enter_voucher: "Enter your voucher code (if any)",
		empty_voucher: "You must enter a voucher to continue",
		invalid_voucher: "Your voucher code is invalid",
		i_agree: "I Agree",
		legal_data: "Legal & Data",
		validate: "Validate",
		accept_data: "Accept Data",
		accept_data_body:
			"By turning ON the Accept Data toggle, you agree cookies and other non-essential technologies can be used for the purposes referenced above and for the sharing of your data with Sony Pictures and Sony group companies.",
		legal_one_body:
			"This app uses cookies and other technologies to improve its operation, make it more personal for you,and provide you with advertising based on your browsing activities and interests on this app and on other apps and sites. To learn more see our Privacy Policy below and in the Legal section of your BRAVIA CORE Account Settings menu. To make choices regarding specific cookies and other non-essential technologies, see Accept Data and Other Data below and in the Legal section of your BRAVIA CORE Account Settings menu where you may also withdraw your consent after consent is given. You do not need to accept cookies and other non-essential technologies to use this app but turning them off may affect your experience.",
		other_data: "Other Data",
		other_data_body:
			"By turning ON the Other Data toggle, you agree cookies and other non-essential technologies can be used for the purposes referenced above and by these analytics providers to personalize the app, content and advertising and to make them function better.",
		google_analytics: "Google Analytics",
		video_analytics: "Video Analytics",
		i_agree_all: "I agree to all of the above",
		i_read_agree: "I have read and agree to the following:",
		promotional_terms: "Promotional Terms & Conditions",
		terms_of_use: "Terms of Use",
		terms_of_service: "Terms of Service",
		sony_pictures: "Sony Pictures",
		sony_group: "Sony Group Companies",
		recover_password: "Recover Password",
		enter_password: "Enter your password",
		legal_terms: "Legal Terms",
		voucher_code: "Voucher Code",
		date_of_birth: "Date of Birth",
		submit: "Submit",
		select_screen_to_manage: "Select the screens you want to manage",
		view: "View",
		details: "Details",
		assign_single_screen_message: "Assigning to screens is currently unavailable because there is only one (1) active screen.",
		directed_by: "Directed by",
		starring: "Starring",
		audio_languages: "Available Audio Languages",
		closed_captions: "Closed Captions",
		other_devices: "Other Devices",
		no_other_screens_available: "No other screens available",
		short_clips: "Short Clips",
		banner_text: "Go deeper into the creation of your favorite movies with exclusive Behind the Scenes content.",
		resume: "Resume",
		assign_to_screens: "Assign to Screens",
		purchase: "Purchase Options",
		redeem_for_one: "Redeem for 1 Credit",
		device_name: "Device Name",
		my_account: "My Account",
		my_subscription: "My Subscription",
		redeem_voucher: "Redeem Voucher",
		edit: "Edit",
		change_password: "Change Password",
		transaction_history: "Transaction History",
		accessibility: "Accessibility",
		light_mode: "Light Mode",
		on: "ON",
		off: "OFF",
		help_and_about: "Help and About",
		legal: "Legal",
		your_privacy_choices: "Your Privacy Choices",
		data_management: "Data Management",
		privacy_policy: "Privacy Policy",
		credit_balance: "Credit Balance",
		account: "Account",
		privacy_spiel: " To complete this request, please visit www.sonypicturescore.com",
		go_behind_the_scenes: "Go Behind The Scenes",
		car_in_motion_title: "Drive Mode Activated",
		car_in_motion_body: "The car is in motion. Some features have been temporarily disabled.",
		car_in_motion_body_login: "The car is in motion. You will need to put the car in park to sign up or log in.",
		car_in_motion_subtext: "This popup will automatically dismiss once the car is parked.",
		car_in_motion_back_to_home: "Back to Home Page",
		car_in_motion_back_to_movie: "Back to Movie Page",
		confirm: "Confirm",
		button_close: "Close",
		subtitle_preview_text: "This is a preview of your closed caption settings",
		video_audio: "Audio",
		video_subtitles: "Subtitles",
		settings_subtitle_options: "Subtitle options",
		subtitle_preview: "Preview",
		subtitle_reset: "Reset Subtitles",
		video_font_settings: "Font",
		font_settings_color: "Color",
		font_settings_text_size: "Text Size",
		font_settings_text_edge: "Text Edge Style",
		font_settings_text_opacity: "Text Opacity",
		font_settings_family: "Font Family",
		font_settings_text_size_default: "Default (20)",
		font_settings_text_style_drop_shadow: "Drop Shadow",
		font_settings_text_style_raised: "Raised",
		font_settings_text_style_depressed: "Depressed",
		font_settings_text_style_uniform: "Uniform",
		video_bg_and_border: "Background & Border",
		video_bg_and_border_bg_color: "Background Color",
		video_bg_and_border_border_color: "Border Color",
		video_bg_and_border_bg_opacity: "Background Opacity",
		video_bg_and_border_border_opacity: "Border Opacity",
		restore_default: "Restore Default",
		none: "None",
		subtitle_subtitles: "Subtitles",
		subtitle_language: "Subtitle Language",
		subtitle_reset_reset: "Reset",
		subtitle_roboto: "Roboto",
		subtitle_comic: "Comic Sans",
		subtitle_times: "Times New Roman",
		subtitle_arial: "Arial",
		subtitle_sans: "Sans",
		subtitle_courier: "Courier",
		subtitle_roboto_mono: "Roboto Mono",
		subtitle_pinyon_script: "Pinyon Script",
		subtitle_carrois_gothic: "Carrois Gothic SC",
		subtitle_black: "Black",
		subtitle_white: "White",
		subtitle_red: "Red",
		subtitle_purple: "Purple",
		subtitle_blue: "Blue",
		subtitle_green: "Green",
		subtitle_yellow: "Yellow",
		subtitle_orange: "Orange",
		subtitle_magenta: "Magenta",
		subtitle_cyan: "Cyan",
		continue: "Continue",
		demo_options: "Demo Options",
		select_your_screen: "Select Your Screen",
		in_car_screens: "In-Car Screens",
		screens: "Screens",
		screen: "Screen",
		redeem_content: "You can redeem this movie using your credit balance to enjoy the full experience.",
		available_audio_language: "Available Audio Language:",
		available_closed_caption_language: "Available Closed Caption Language:",
		redeem_credit: "Redeem for 1 Credit",
		redeem_success: "Redeemed for 1 credit. Your new balance is %d credits.",
		redeem_limit: "You have reached maximum redeem attempts. Try again after few minutes",
		purchase_rent_content: "You can choose to rent it or purchase it to enjoy watching. See purchase options below:",
		purchase_only_content: "You can purchase it to enjoy watching.",
		rent_only_content: "You can rent it to enjoy watching.",
		rent_for: "Rent for ",
		rent_expiry: "(Expires in 30 days)",
		purchase_for: "Purchase for ",
		movie_not_available_user: "Movie is not available for user.",
		error_purchase: "An error occurred while purchasing the movie.",
		concurrent_limit: "Your concurrent streaming limit has been reached.",
		rear_user: "Rear Screen",
		modal_restore_body: "This will restore all user-defined viewing options back to its original state.",
		modal_accept: "I Accept",
		not_yet_redeem: "Movie is not yet redeemed",
		reconnect_failed: "Failed to reconnect. Redirecting back to the Movie Title Page.",
		network_reconnecting: "Network connection lost. Reconnecting",
		default_settings_restored: "Default settings restored",
		toast_invalid_credential: "Invalid credentials.",
		error_signin: "An error occurred while signing in.",
		maximum_attempt: "You have reached the maximum login attempts. Try again after a few minutes",
		label_retry: "RETRY",
		label_view_details: "VIEW DETAILS",
		movie_rented: "Movie successfully rented.",
		movie_purchased: "Movie successfully purchased.",
		need_to_redeem: "You need to redeem, purchase or rent the movie first before you can enjoy these content",
		assign_media_to_screen: "Assign media to screens or tap any media to play",
		select_media_to_assign: "Select the media you want to assign",
		select_screen_to_play: "Select the screens you want to play this movie on",
		play_success: "Played successfully",
		paused_success: "Paused successfully",
		resume_success: "Resumed successfully",
		restart_success: "Restarted successfully",
		stop_success: "Stopped successfully",
		sync_success: "Synced successfully",
		sync_body: "Select the content you want to sync the screens with.",
		action_in_motion: "In Motion",
		success_change_pass: "Password Changed!",
		change_pass_modal: "Your password has been changed successfully.",
		return_to_settings: "Return to Settings",
		error_redeem: "An error occurred while redeeming the voucher.",
		voucher_empty: "The voucher code must not be empty.",
		success_redeem_voucher: "Voucher redemption success",
		voucher_bundle_not_active: "The voucher bundle is not active.",
		voucher_supplied_invalid: "The voucher code supplied is invalid.",
		voucher_required: "A voucher code is required.",
		voucher_specified_invalid: "The voucher code specified was invalid.",
		voucher_expired: "The voucher code specified has expired.",
		voucher_used: "The voucher code specified has already been used.",
		voucher_not_active: "The voucher code is not yet active.",
		voucher_device_not_valid: "The device is not valid for this voucher.",
		voucher_promotion: "This voucher code belongs to an alternative promotion.",
		voucher_device_not_entitled: "Device not entitled to voucher.",
		credit_added: "credits have now been added to your account.",
		validate_voucher: "Validating voucher...",
		advanced: "Advanced",
		purchase_content: "This is a premium movie. You can choose to rent it or \npurchase it to enjoy watching. See purchase options below:",
		movie_already_owned: "Movie is already purchased.",
		error_validation: "An error was encountered during validation.",
		billing_validated: "Some information on the address you have entered could not be verified. Below is the validated address:",
		billing_not_validated: "We could not validate the address you have provided.",
		original_address: "Original Address",
		validated_address: "Validated Address",
		no_information: "Not enough information to use",
		retry_limit: "You have reached the maximum limit of retries. Try again in a few minutes",
		invalid_security_code: "You entered invalid CCV/CVC",
		invalid_credit_card: "You entered invalid Card number",
		rent: "Rent",
		purchase_word: "Purchase",
		previous: "Previous",
		error_occurred: `An error occurred while %d the movie.`,
		card_details: "Card Details",
		billing_information: "Billing Information",
		review_your_order: "Review Your Order",
		validating_do_not_stray: "Validating. Please do not stray away from this page…",
		tax_apply: `%d for %p. Taxes may apply.`,
		cancel_purchase_transaction: "Cancel Purchase Transaction",
		validating_cancel_transaction: "Are you sure you want cancel the current transaction?",
		total: "Total",
		rent_price: "Rent Price",
		purchase_price: "Purchase Price",
		tax: "Tax",
		vat: "VAT",
		purchase_agree: "By clicking Purchase, I hereby agree to the ",
		enter_country_code: "Enter or select Country Code",
		phone_number: "Phone Number",
		email_address: "Email Address",
		first_name: "First Name",
		last_name: "Last Name",
		card_number: "Card number",
		expiry_date: "Expiry date",
		address_1: "Address 1",
		address_2: "Address 2",
		address_optional: "Address 2 (optional)",
		street: "Street and number, P.O. Box, c/o.",
		country: "Country",
		state: "State",
		city: "City",
		zip_postal_code: "Zip/Postal Code",
		select_one: "Select one",
		search_results_for: "Search results for",
		retype_new_password: "Re-type new password",
		temporary_password: "Enter temporary password",
		current_password: "Enter current password",
		send_temporary_password: "Send Temporary Password",
		temporary_password_modal_header: "Temporary password sent",
		temporary_password_modal_body:
			"Please check your email inbox or spam folder for the temporary password sent and use it to log into your account.",
		reset_password: "Reset Password",
		error_reset_password: "An error occurred while sending the email.",
		error_temporary_password: "An error occurred while sending the password.",
		error_change_password: "An error occurred while changing the password.",
		cancel_rent_transaction: "Cancel Rent Transaction",
		enter_valid_email: "Please enter a valid email address",
		valid_age: "Age not permitted",
		enter_valid_birthday: "Please enter a valid date of birth",
		account_exist: "Account already exists!",
		account_created: "Account created successfully!",
		unknown_error: "An unknown error has occurred. Please try again.",
		valid_password:
			"Your password must be at least 6 characters long, contain at least one symbol, one number and have a mixture of uppercase and lowercase letters.",
		modal_logout_header: "Session Idle Timeout",
		modal_logout_body: "You've been idle for 1 hour now. \n Do you want to keep your session? \n (%ds)",
		keep_me_logged_in: "Keep Me Logged In",
		session_expired: "Your current session has expired.",
		validating_credentials: "Validating credentials...",
		no_popular: "No popular searches available yet. \n Please search using the search field above.",
		//NEW
		syncing: "Syncing",
		online: "Online",
		view_options: "Viewing Options",
		in_car: "In-Car",
		this_screen: "This Screen",
		not_subscribed: "Not subscribed",
		expires_on: "Expires on",
		rent_expires: "Rent expires on",
		viewing_expires: "Viewing expires on",
		redeem_on: "Redeem on",
		purchased_on: "Purchased on",
		rented_on: "Rented on",
		refunded_on: "Refunded on",
		for: "for",
		credit: "credit",
		search_keyword: "Search keyword...",
		update: "Update",
		assign_name: "Assign Name",
		edit_device: "Edit your device name",
		device_name_change: "Device name changed",
		device_change_success: "You have successfully changed this device's display name. \nYou can edit this again under Settings.",
		device_name_exist: "The name you entered is already in use. Please use a different name.",
		device_name_required: "Device name is required",
		redeem_voucher_credit: "Redeem voucher to add credits",
		for_faq: "For FAQs, please visit: ",
		problem_exp: "If you are experiencing problems, please contact us at: ",
		sony_acknowledge: "Sony Pictures would like to acknowledge the following open source projects:",
		territory_lang_not_supported: "Territory and language not supported no apikey provided.",
		characters_left: "characters left",
		assign_media_screen: "Assign Media to Screens",
		feature_not_implemented: "This feature is not yet fully implemented, expect some glitches when enabled.",
		sync: "Sync",
		stop: "Stop",
		sure_to_logout: "Are you sure you want to log out?",
		logout_reminder:
			"You will need to enter your password again to access your subscription, redeemed movies and other unique content for RIDEVU on this account.",
		invalid_expiry_date: "Invalid expiry date. Please try again.",
		try_it_now: "Back to Preview",
		processing: "Processing",
		welcome_back: "Welcome back,",
		welcome: "Welcome,",
		add_screen: "Add Screens",
		generate_code: "Generate Code",
		sign_in_with_code: "Sign In with Code",
		download_app: "Companion App for IOS",
		download_app_android: "Companion App for Android",
		sign_in_with_code_desc: "Generate an authorization code to quickly sign into your account on other screens and devices.",
		download_app_desc: "Please scan the QR code to download the companion app to your device. You can also visit this link manually:",
		connect_to_other_screens: "Connect the other in-car screens or your device",
		exit_preview: "Exit Preview",
		authentication_failed:
			"Authentication Failed. Your card issuer cannot authenticate this card. Please select another card or form of payment to complete your purchase.",
		empty_library: "Your library is currently empty.",
		bookmarks_content: "Content you watch, redeem, rent, buy, or add to your bookmarks will appear here.",
		popular_titles: "In the meantime, here are our popular titles:",
		sign_in_with_email: "Sign In with Email",
		hwid_error: "An error occur when decrypting the Hardware ID.",
		available_subtitles: "Available Subtitles",
		preview_mode: "Preview Mode",
		character_code_input: "Enter the 6-character code",
		connection_unauthorized: "Connection unauthorized.",
		login_succes: "Successfully logged in. Redirecting in",
		voucher_automatically_applied: "Voucher Automatically Applied",
		your_voucher: "Your voucher gives you 30 days streaming package",
		redeem_title_of_choice: "credits to redeem titles of your choice",
		membership: "RIDEVU membership",
		co_user: "Front Passenger",
		empty_mylibrary: "Check back here to find titles you have watched, redeemed, purchased or rented.",
		hwid_account_exist: "Account already exists on this car. Please log in.",
		login_main_user_to_register:
			"Please log in your Mercedes with the Mercedes me main user of your vehicle before creating an account in RIDEVU.",
		flex_limit_message: "Payment validation error. Please restart transaction.",
		processing_order: "We are processing your order.",
		//NEW
		error: "Error",
		license_error: "LICENSE_REQUEST_FAILED",
		connection_timeout: "CONNECTION TIMEOUT",
		network_error: "NETWORK DISCONNECTED",
		email_placeholder: "your@email.com",
		help_and_about_body: "Geoff Difford’s Implementations of SMA-1, SHA-2334, S56WWE, and WSA-512",
		ridevue_version: "RIDEVU Version ",
		connection_restored: "CONNECTION RESTORED",
		player_vam_link_header: "Watch the movie now",
		pre_roll_error: "Pre-roll is not working.",
		subtitle_noto_sans: "Noto Sans Japanese",
		invalid_shortcode: "Please enter valid code",
		rental_time_remaining: "Rental Viewing Expires in %1sh %2sm",
		legal_terms_text: "Purchasing this digital good is a license. Your access is limited by these",
	},
};
