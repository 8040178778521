import { AsyncStorageKeys, Categories, Pages, ProfileNameState } from "../../Types";
import { useEffect, useState, useMemo } from "react";
import { useNavigation } from "@react-navigation/native";
import { HomeScreenNavigationProp, routeHome, routeLogin, routePage } from "../../Routes";
import { AppLogger } from "../../utils/AppLogger";
import { getCategories, getCategoriesShared } from "../../services/categoriesService";
import PlaylistContainer from "../../components/Layout/PlaylistContainer";
import { getPage, getPageShared } from "../../services/pageService";
import TopAppBar from "../../components/TopAppBar";
import backIcon from "../../assets/TopNavBarIcon/icon_nav_back.svg";
import styled from "styled-components/native";
import { light_black } from "../../StyleHelpers";
import AggregatePageLoader from "../../components/Loaders/AggregatePageLoader";
import Banner from "./Banner";
import CustomizeLoader from "../../components/Loaders/CustomizeLoader";
import useGenericContentStore from "../../store/genericContent.store";
import ridevueLogo from "../../assets/Logo/ridevuLogo.svg";
import ExitPreviewButton from "../../components/ExitPreviewButton";
import { addNewJSONStringsAsKeysIfNeeded, generateDeviceInfo, getTransactionFilters, globalAny, pushProfileNameStateToBrowserHistory, validateCustomerZone } from "../../utils/Utils";
import useLoginStore from "../../store/useLogin.store";
import { getLanguage as getLanguageApi } from "../../services/appWordingService";
import Toast from "../../components/Toast";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import { EN } from "../../utils/Strings";
import useMqttStore from "../../store/useMqtt.store";
import useLegalContentStore from "../../store/useLegalContent.store";
import LandingLoader from "../../components/Loaders/LandingLoader";
import { Code_OK, getPresignedUrl } from "../../networking/networking";
import { infoEmail } from "../../services/accountService";
import { linkedDevice } from "../../services/loginService";
import useToggleStore from "../../store/useToggle.store";

const transactionalFilters = getTransactionFilters();

const StyledContainer = styled.View`
	height: 100vh;
	background-color: ${light_black};
`;

const Spacer = styled.View`
	margin-bottom: 3%;
`;

const TopSpacer = styled.View`
	margin-top: 3%;
`;

const StyledGridPackshots = styled.View`
	margin-bottom: 3%;
	padding-top: 115px;
	padding-bottom: 20px;
`;

const defaultItem = [
	{
		id: 1,
		isLandscape: true,
		packshot: [1, 2, 3, 4, 5],
	},
	{
		id: 2,
		isLandscape: false,
		packshot: [1, 2, 3, 4, 5, 6, 7],
	},
	{
		id: 3,
		isLandscape: false,
		packshot: [1, 2, 3],
	},
];

const Page = (props: any) => {
	const navigation = useNavigation<any>();
	const navigationHomeScreen = useNavigation<HomeScreenNavigationProp>();
	const { customerzone, language, wheelpos, hwid, masteruser, uniqueId, enableLogs, enableMQTT, disableAutoLogin } = props?.route?.params ?? "";
	const [categories, setCategories] = useState([]);
	const [pageDetails, setPageDetails] = useState<any>();
	const [title, setTitle] = useState("");
	const [banner, setBanner] = useState("");
	const [isShowLoader, setIsShowLoader] = useState(true);
	const [isAggregate, setIsAggregate] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const setNavigateEvent = useGenericContentStore((state: any) => state.setNavigateEvent);
	const navigateEvent = useGenericContentStore((state: any) => state.navigateEvent);
	const setUniqueId = useGenericContentStore((state: any) => state.setUniqueId);
	const isLoggedIn = useLoginStore((state: any) => state.setIsLoggedIn);
	const isInitialLoading = useLoginStore((state: any) => state.isInitialLoading);
	const setIsInitialLoading = useLoginStore((state: any) => state.setIsInitialLoading);
	const showHWIDError = useGenericContentStore((state: any) => state.showHWIDError);
	const setShowHWIDError = useGenericContentStore((state: any) => state.setShowHWIDError);
	const fetchLegals = useLegalContentStore((state: any) => state.fetchLegals);
	const fetchAcceptOtherData = useLegalContentStore((state: any) => state.fetchAcceptOtherData);
	const setPresignedUrl = useMqttStore((state: any) => state.setPresignedUrl);
	const setIsLogin = useGenericContentStore((state: any) => state.setIsLogin);
	const setDeviceInfo = useMqttStore((state: any) => state.setDeviceInfo);
	const setIsLoggedIn = useLoginStore((state: any) => state.setIsLoggedIn);
	const isToggleAutoLogin = useToggleStore((state: any) => state.isToggleAutoLogin);

	const isPreview = () => uniqueId === Pages.preview || !uniqueId;
	const [startIndex, setStartIndex] = useState(isPreview() ? 5 : 4);

	const renderPlaylist = useMemo(
		() => (
			<PlaylistContainer
				headerBanner={banner && !isPreview() ? <Banner banner={banner} /> : null}
				categories={categories}
				isSeeMore={true}
				location={Categories.home}
				transactionalFilters={transactionalFilters}
				setIsShowLoader={setIsShowLoader}
				setIsAggregate={setIsAggregate}
				setStartIndex={setStartIndex}
				startIndex={startIndex}
				isPreview={isPreview() || navigateEvent === Pages.preview}
			/>
		),
		[categories, startIndex, banner]
	);

	const onClose = () => {
		if (isPreview()) {
			setNavigateEvent("");
			navigation.navigate(routeLogin);
			return;
		}

		if (navigateEvent === Pages.preview) {
			setNavigateEvent("");
			navigation.navigate(routePage, { uniqueId: Pages.preview });
			return;
		}

		setNavigateEvent("");
		navigation.navigate(routeHome);
	};

	const onBackPress = () => {
		onClose();
	};

	const onPreview = (signal: any) => {

		setTitle("Preview");
		(async () => {
			try {
				let categories: any = (await getCategoriesShared(Pages.preview, transactionalFilters, signal)) ?? [];
				const heroPanels = categories.find((cat: any) => cat.featureTypes.find((type: any) => type === "HeroPanel"));
				if (heroPanels) {
					categories = [heroPanels, ...categories.filter((cat: any) => cat.id !== heroPanels.id)];
				}
				setCategories(categories);
			} catch (err) {
				AppLogger.log(err);
			}
		})();
	};

	const exitPreview = () => {
		navigation.navigate(routeLogin);
	};

	const renderExitPreview = () => {
		return <ExitPreviewButton onPress={() => exitPreview()} />;
	};

	const isCustomerZoneParamExist = () => customerzone;

	useEffect(() => {
		if (props?.route?.params)
			localStorage.setItem(
				AsyncStorageKeys.dataExchange,
				JSON.stringify(Object.keys(props?.route?.params).filter((keys) => keys !== "uniqueId"))
			);
	}, [props?.route?.params]);

	useEffect(() => {
		if (uniqueId) setUniqueId(uniqueId);
		if (!isPreview() && navigateEvent !== Pages.preview) setNavigateEvent(Pages.page);
	}, [uniqueId]);

	useEffect(() => {
		if (pageDetails) {
			const [pageTitle] = pageDetails.pageTranslations.filter((translation: any) => translation.name.toLowerCase() === "title");
			setTitle(pageTitle.text);

			if (pageDetails?.pageImages) {
				const [pageImage] = pageDetails.pageImages;
				setBanner(pageImage?.imageUrl);
			}
		}
	}, [pageDetails]);

	useEffect(() => {
		if (isInitialLoading) setIsShowLoader(true);
	}, [isInitialLoading]);

	useEffect(() => {
		if (!uniqueId && isLoggedIn && globalAny.profileName) {
			navigationHomeScreen.navigate(routeHome, {});
		}
	}, [isLoggedIn]);

	useEffect(() => {
		if (isCustomerZoneParamExist()) {
			validateCustomerZone(customerzone);
		}
	}, [customerzone]);

	useEffect(() => {
		if (masteruser) {
			localStorage.setItem(AsyncStorageKeys.masteruser, masteruser);
		}
	}, [masteruser]);

	useEffect(() => {
		(async () => {
			if (language) {
				setIsInitialLoading(true);
				const splittedLanguage = language.split("_");

				if (splittedLanguage.length == 2) {
					localStorage.setItem(AsyncStorageKeys.LanguageOverride, splittedLanguage[0].toUpperCase() ?? "EN");
					localStorage.setItem(AsyncStorageKeys.RegionOverride, splittedLanguage[1].toUpperCase() ?? "US");
					document.documentElement.setAttribute('lang', splittedLanguage[0].toUpperCase());
					localStorage.removeItem(AsyncStorageKeys.languageJson);
					let languageJSON = await getLanguageApi();
					if (languageJSON) {
						// @ts-ignore
						globalAny.language = { ...EN.Strings, ...languageJSON, pass_rules: EN.Strings["pass_rules"] };

						// add keys if they dont exist on server, but do locally
						addNewJSONStringsAsKeysIfNeeded();
						localStorage.setItem(AsyncStorageKeys.languageJson, JSON.stringify(languageJSON));
					}
				}

				try {
					await fetchLegals(AsyncStorageKeys.legals);
					await fetchAcceptOtherData(AsyncStorageKeys.acceptOtherData);
				} catch (err) {
					AppLogger.log(err);
				}
			}
		})();
	}, [language]);

	useEffect(() => {
		if (wheelpos) {
			localStorage.setItem(AsyncStorageKeys.wheelPosition, wheelpos);
		}
	}, [wheelpos]);

	const setProfileName = async (deviceInfo: any) => {
		localStorage.setItem(AsyncStorageKeys.deviceId, deviceInfo.deviceId);
		globalAny.profileName = deviceInfo.deviceName;
	};

	const updateDevice = (profileName: any) => {
		const deviceInfo = generateDeviceInfo(profileName);
		setDeviceInfo(deviceInfo);
		setProfileName(deviceInfo);
	};

	useEffect(() => {
		if (uniqueId) {
			setIsLoading(false);
			return;
		}

		const currentHWID = localStorage.getItem(AsyncStorageKeys.hwid);
		if (hwid) localStorage.setItem(AsyncStorageKeys.hwid, hwid);

		if (hwid || currentHWID) {
			if (!isToggleAutoLogin || disableAutoLogin) {
				setIsLoading(false);
				return;
			}

			setIsLoading(true);

			(async () => {
				const autoLogin: any = await linkedDevice();
				if (!autoLogin) {
					setIsLoading(false);
					return;
				};

				if (autoLogin.responseCode === Code_OK) {
					const presignedService = await getPresignedUrl();
					setPresignedUrl(presignedService);
					const userInfo: any = await infoEmail();
					localStorage.setItem(AsyncStorageKeys.email, userInfo.email);
					const isInCarScreen = localStorage.getItem(AsyncStorageKeys.isInCarScreen) == "true";
					pushProfileNameStateToBrowserHistory(ProfileNameState.profileNameSelect);
					setIsLogin(true);
					setIsLoading(false);
					if (!isInCarScreen) {
						//@ts-ignore
						navigationProfileNameSelectScreen.navigate(routeProfileNameSelect, { location: Pages.main });
					}
					if (isInCarScreen) {
						const profileName = localStorage.getItem(AsyncStorageKeys.profileName);
						updateDevice(profileName);
						setIsLoggedIn(true);
						navigationHomeScreen.navigate(routeHome, {});
					}
					return;
				}
				setIsLoading(false);
			})();
		}
	}, [hwid, isToggleAutoLogin]);

	useEffect(() => {
		localStorage.removeItem(AsyncStorageKeys.enableLogs);
		if (enableLogs) {
			localStorage.setItem(AsyncStorageKeys.enableLogs, enableLogs);
		}
	}, [enableLogs]);

	useEffect(() => {
		localStorage.removeItem(AsyncStorageKeys.enableMQTT);
		if (enableMQTT) {
			localStorage.setItem(AsyncStorageKeys.enableMQTT, enableMQTT);
		}
	}, [enableMQTT]);

	useEffect(() => {
		const controller = new AbortController();

		if (isPreview()) {
			onPreview(controller.signal);
			return;
		}
		(async () => {
			try {
				const pages: any = navigateEvent === Pages.preview ? await getPageShared(uniqueId) : await getPage(uniqueId, controller.signal);
				const [page] = pages.pages;
				setPageDetails(page);
			} catch (err) {
				AppLogger.log(err);
			}
		})();
		(async () => {
			try {
				let categories: any =
					navigateEvent === Pages.preview
						? await getCategoriesShared(uniqueId, transactionalFilters)
						: (await getCategories(uniqueId, transactionalFilters)) ?? [];
				const heroPanels = categories.find((cat: any) => cat.featureTypes.find((type: any) => type === "HeroPanel"));
				if (navigateEvent === Pages.preview && heroPanels) {
					categories = [heroPanels, ...categories.filter((cat: any) => cat.id !== heroPanels.id)];
				}
				setCategories(categories);
			} catch (err) {
				AppLogger.log(err);
			}
		})();

		return () => {
			setCategories([]);
		};
	}, []);

	if (isLoading) {
		return <LandingLoader />;
	}

	return (
		// @ts-ignore
		<StyledContainer>
			<TopAppBar
				leftIcon={backIcon}
				leftIconWidth={92}
				leftTitleIcon={isPreview() ? "" : backIcon}
				isHideLeftIcon={false}
				screenTitle={isPreview() ? "" : title}
				titleLogo={isPreview() ? ridevueLogo : null}
				onPressLeftTitleIcon={isPreview() ? () => {} : onBackPress}
				onPressLeftIcon={onClose}
				isPreview={isPreview()}
			/>
			{isShowLoader && !isPreview() && (
				// @ts-ignore
				<StyledGridPackshots>
					<TopSpacer />
					<AggregatePageLoader />
				</StyledGridPackshots>
			)}
			{isShowLoader && isPreview() && (
				<CustomizeLoader isPreview={true} show={isShowLoader} isHeroBanner={true} isPlaylist={true} data={defaultItem}></CustomizeLoader>
			)}
			{renderPlaylist}
			{isPreview() && renderExitPreview()}
			{isAggregate && !isPreview() && renderExitPreview()}

			{showHWIDError ? (
				<Toast
					visible={showHWIDError}
					text={globalAny.language.hwid_error}
					type={ComponentTypeEnum.Secondary}
					onDismissSnackBar={() => setShowHWIDError(false)}
				/>
			) : (
				<></>
			)}
		</StyledContainer>
	);
};

export default Page;
