import { SafeAreaView, ScrollView, Dimensions, TouchableHighlight } from "react-native";
import { useEffect, useState } from "react";
import { useNavigation } from "@react-navigation/native";
import {
	routeHome,
	routePayment,
	routeScreenManager,
	PaymentScreenNavigationProp,
	routePlayer,
	routeTrailer,
	routeLogin,
	routePage,
	routeSearchScreen,
	routeDataManagementSettings,
	routeMyLibrary,
	routeAggregatePage,
	routeNewCuration,
	routeVamPlayer,
	routeLegalsScreen,
} from "../../Routes";
import { black, spacing_l } from "../../StyleHelpers";
import { Metadata } from "../../models/Metadata";
import styledRN from "rn-css";
import styled, { css } from "styled-components/native";
import TopAppBar from "../../components/TopAppBar/index";
import closeIcon from "../../assets/TopNavBarIcon/icon_nav_close.svg";
import backIcon from "../../assets/TopNavBarIcon/icon_nav_back.svg";
import TabsView from "../../components/TabsView";
import ViewDetails from "./ViewDetails";
import Details from "./Details";
import GradientLeftSide from "../../components/GradientLayer/GradientLeftSide";
import Image from "../../components/Image/Image";
import TitleDetailsLoader from "../../components/Loaders/TitleDetailsLoader";
import ImgProgressBar from "../../components/Loaders/ImgProgressBar";
import CustomizeDialogBox from "../../components/DialogMessageBox/CustomizeDialogBox";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import { redeemProductV8 } from "../../services/purchaseService";
import Toast from "../../components/Toast";
import { AsyncStorageKeys, Pages, direction, PurchaseTransactionType, CarActivity } from "../../Types";
import AsyncStorage from "@react-native-async-storage/async-storage";
import useGenericContentStore from "../../store/genericContent.store";
import Extras from "./Extras";
import { getProgressVideo } from "../../services/progressVideoService";
import {
	convertDuration,
	convertToLanguage,
	getCurrencySymbol,
	getVideoQualityByDeviceHeight,
	globalAny,
	historyFormat,
	isCoPassenger,
	metadataTextSeparator,
	onCheckingNetwork,
} from "../../utils/Utils";
import { AppLogger } from "../../utils/AppLogger";
import * as CacheStorageUtils from "../../utils/CacheStorageUtils";
import { purchaseHistory, productPrice } from "../../services/paymentService";
import PrivacyPolicy from "../../components/PrivacyPolicy";
import usePaymentTransactionStore from "../../store/usePaymentTransaction.store";
import { accountCredits, accountCreditsExpiration, accountSubscription } from "../../services/accountService";
import { TRANSACTION_TYPES } from "../../networking/networking";
import { getAvailableAudioLanguages, getConcurrency, getPlaybackWindow, postConcurrency } from "../../services/videoService";
import { getTrailer } from "../../services/trailerService";
import CarActivityDialog from "../../components/CarActivityDialog";
import { getAvailability } from "../../services/metadataService";
import { responseOk } from "../../utils/ValidateResponse";
import useMovieContentStore from "../../store/useMovieContent.store";
import useDrivingStore from "../../store/useDriving.store";
import useMqttStore from "../../store/useMqtt.store";
import ControllerContent from "../../components/ControllerContent";
import ScreenIcon from "../../assets/ControllerIcon/Screen-Icon.svg";
import ExitPreviewButton from "../../components/ExitPreviewButton";
import { v4 as uuidv4 } from "uuid";
import useSearchDataStore from "../../store/useSearchData.store";
import useToggleStore from "../../store/useToggle.store";
import Marquee from "react-fast-marquee";
import { Text } from "react-native";
import useLegalContentStore from "../../store/useLegalContent.store";

interface Props {
	bgImage?: any;
	packshot?: any;
}

const StyledContainer = styled.View`
	height: 100vh;
	background-color: ${black};
`;

const StyledTabsContainer = styled.View`
	width: 100%;
	background-color: ${black};
`;

const StyledMain = styled.View<Props>`
	height: 100%;
	width: 100%;
	z-index: -1;
	position: relative;

	${(props) =>
		!props.bgImage &&
		css`
			background-color: black;
		`}

	${(props) =>
		props.bgImage &&
		css`
			background-image: url(${props.bgImage});
			background-position: top center;
			background-repeat: no-repeat;
			background-size: cover;
		`}
`;

const StyledMarquee = styled.View<any>`
	cursor: ${(props) => (props.hover ? "pointer" : "normal")};
`;

const TabsContainer = styled.View`
	margin: 120px 0 0 0;
	position: absolute;
	width: 100%;
	z-index: -1;
	background-color: black;
`;

const StyledContainerColumn = styled.View`
	flex-direction: row;
	height: 100%;
`;

const StyledImage = styled.View`
	margin-top: ${spacing_l};
	display: flex;
`;

const StyledPackshot = styledRN.View`
	width: 420px;
	height: 630px;
	border-radius: 16px;
	overflow: hidden;
	position: absolute;

	@media (max-width: 640px) {
		margin-top:50px;
		width: 160px;
		height: 270px;
	}
	@media (min-width: 640px) {
		width: 300px;
		height: 480px;
	}
	@media (min-width: 768px) {
		margin-left:60px;
		width: 320px;
		height: 500px;
	}
	@media (min-width: 1536px) {
		margin-left:112px;
		width: 420px;
		height: 630px;
	}
`;

const StyledLoading = styledRN.View`
	width: 420px;
	height: 630px;
	border-radius: 16px;
	overflow: hidden;
	position: absolute;
	z-index: -1;
	@media (max-width: 640px) {
		margin-top:50px;
		width: 160px;
		height: 270px;
	}
	@media (min-width: 640px) {
		width: 300px;
		height: 480px;
	}
	@media (min-width: 768px) {
		margin-left:60px;
		width: 300px;
		height: 480px;
	}
	@media (min-width: 1536px) {
		margin-left:112px;
		width: 420px;
		height: 630px;
	}
`;

const contentPerRow = 24;

const NewTitleDetails = (props: any) => {
	const navigation = useNavigation<any>();
	const navigationPayment = useNavigation<PaymentScreenNavigationProp>();
	const { route } = props;
	const { parentProductId, preview } = route.params;
	const [metadata, setMetadata] = useState<Metadata>();
	const [backgroundImage, setBackgroundImage] = useState("");
	const [ratingReason, setRatingReason] = useState("");
	const [progressBar, setProgressBar] = useState(0);
	const [streamProgress, setStreamProgress] = useState(0);
	const [director, setDirector] = useState([]);
	const [ownProduct, setOwnProduct] = useState(false);
	const [packshot, setPackshot] = useState();
	const [selectedProductRedeemed, setSelectedProductRedeemed] = useState<any>();
	const [showRedeemModal, setShowRedeemModal] = useState(false);
	const [showPurchaseModal, setShowPurchaseModal] = useState(false);
	const [visible, setVisible] = useState(false);
	const [toastMessage, setToastMessage] = useState("");
	const [labelText, setLabelText] = useState("");
	const [type, setType] = useState(ComponentTypeEnum.Secondary);
	const [vamData, setVamData] = useState<any>({});
	const [channelData, setChannelData] = useState([]);
	const [hasCredit, setHasCredit] = useState(false);
	const [showCarToast, setShowCarToast] = useState(false);
	const [isRent, setIsRent] = useState<any>(null);
	const [expiryDate, setExpiryDate] = useState({});
	const [availabilityRedeem, setAvailabilityRedeem] = useState(false);
	const [availabilityPurchase, setAvailabilityPurchase] = useState(false);
	const [availabilityRent, setAvailabilityRent] = useState(false);
	const [noVamData, setNoVamData] = useState(false);
	const [isProcessing, setIsProcessing] = useState(false);
	const [movieAvailableLang, setMovieAvailableLang] = useState("");
	const [audioLang, setAudioLang] = useState("");
	const [subtitleLang, setSubtitleLang] = useState("");
	const [isTrailer, setIsTrailer] = useState(false);
	const [ownedTransaction, setOwnedTransaction] = useState<any>({});
	const NO_IMAGE_RETURNED = "";
	const onDismissSnackBar = () => setVisible(false);
	const movieStoreValue = useMovieContentStore((state: any) => state.data);
	const fetchTitleDetails = useMovieContentStore((state: any) => state.fetchTitleDetails);
	const fetchTitleDetailsPreview = useMovieContentStore((state: any) => state.fetchTitleDetailsPreview);
	const selectedTabs = useGenericContentStore((state: any) => state.selectedTabs);
	const setSelectedTabs = useGenericContentStore((state: any) => state.setSelectedTabs);
	const setVamDetails = useGenericContentStore((state: any) => state.setVamDetails);
	const navigateEvent = useGenericContentStore((state: any) => state.navigateEvent);
	const setNavigateEvent = useGenericContentStore((state: any) => state.setNavigateEvent);
	const isSuccess = useGenericContentStore((state: any) => state.isSuccess);
	const dimensions = useGenericContentStore((state: any) => state.dimensions);
	const setIsSuccess = useGenericContentStore((state: any) => state.setIsSuccess);
	const hasRent = usePaymentTransactionStore((state: any) => state.hasRent);
	const setHasRent = usePaymentTransactionStore((state: any) => state.setHasRent);
	const setPurchasePrice = usePaymentTransactionStore((state: any) => state.setPurchasePrice);
	const setRentPrice = usePaymentTransactionStore((state: any) => state.setRentPrice);
	const mistakeCount = useGenericContentStore((state: any) => state.mistakeCount);
	const setMistakeCount = useGenericContentStore((state: any) => state.setMistakeCount);
	const carActivity = useDrivingStore((state: any) => state.carActivity);
	const mqttDevices = useMqttStore((state: any) => state.mqttDevices);
	const setPlaySessionId = useMqttStore((state: any) => state.setPlaySessionId);
	const currencySymbol = usePaymentTransactionStore((state: any) => state.currencySymbol);
	const setCurrencySymbol = usePaymentTransactionStore((state: any) => state.setCurrencySymbol);
	const lastSearchData = useSearchDataStore((state: any) => state.lastSearchData);
	const aggregatePageId = useGenericContentStore((state: any) => state.aggregatePageId);
	const uniqueId = useGenericContentStore((state: any) => state.uniqueId);
	const isRenderTDP = useGenericContentStore((state: any) => state.isRenderTDP);
	const setIsRenderRedeem = useGenericContentStore((state: any) => state.setIsRenderRedeem);
	const setIsRenderHome = useGenericContentStore((state: any) => state.setIsRenderHome);
	const isToggleMQTT = useToggleStore((state: any) => state.isToggleMQTT);
	const fetchLegals = useLegalContentStore((state: any) => state.fetchLegals);
	const [textRent, setTextRent] = useState("");
	const [textPurchase, setTextPurchase] = useState("");
	const [windowWidth, setWindowWidth] = useState<any>();
	const [gridSize, setGridSize] = useState<any>(contentPerRow);
	const [extraLoader, setExtraLoader] = useState(false);
	const [next, setNext] = useState(contentPerRow);
	const [availableTransactionType, setAvailableTransactionType] = useState(0);
	const [isLoadingButton, setIsLoadingButton] = useState(true);
	const [isShowOptions, setIsShowOptions] = useState(false);
	const [isRedeem, setIsRedeem] = useState(false);
	const [isPurchase, setIsPurchase] = useState(false);
	const [isRented, setIsRented] = useState(false);
	const [isHovered, setIsHovered] = useState(false);
	const isDefaultTerritory = localStorage.getItem(AsyncStorageKeys.RegionOverride) == "US";

	const controllerData = [
		{ id: 0, icon: ScreenIcon, buttonText: globalAny.language.assign_to_screens, textDescription: globalAny.language.assign_media_to_screen },
		{ id: 1, icon: "", buttonText: globalAny.language.cancel, textDescription: globalAny.language.select_media_to_assign },
	];
	const [controller, setController] = useState(controllerData[0]);
	const [id, setId] = useState(0);

	let menuList = [
		{ title: globalAny.language.view, disabled: false },
		{ title: globalAny.language.details, disabled: carActivity === CarActivity.INMOTION },
		{ title: globalAny.language.extras, disabled: noVamData ? true : carActivity === CarActivity.INMOTION },
	];

	let menuListPreview = [
		{ title: globalAny.language.view, disabled: false },
		{ title: globalAny.language.details, disabled: carActivity === CarActivity.INMOTION },
	];
	const [menuItem, setMenuItem] = useState(menuList);

	const wording = {
		redeemContent: globalAny.language.redeem_content,
		modalFooter: `${globalAny.language.available_audio_language} English, Spanish, French, German, Italian<br> ${globalAny.language.available_closed_caption_language} English, Spanish, French, German, Italian`,
		redeemCredit: globalAny.language.redeem_credit,
		redeemSuccess: globalAny.language.redeem_success,
		redeemLimit: globalAny.language.redeem_limit,
		purchaseRentContent: globalAny.language.purchase_rent_content,
		purchaseOnlyContent: globalAny.language.purchase_only_content,
		rentOnlyContent: globalAny.language.rent_only_content,
		rent: globalAny.language.rent_for,
		rentExpiry: globalAny.language.rent_expiry,
		purchase: globalAny.language.purchase_for,
		movieNotAvailable: globalAny.language.movie_not_available_user,
		errorPurchase: globalAny.language.error_purchase,
		cancel: globalAny.language.cancel,
		concurrentLimit: globalAny.language.concurrent_limit,
		processing: globalAny.language.processing,
		legalText: globalAny.language.legal_terms_text,
	};

	//#region Function
	/**
	 * Fetch price of current ProductId
	 * Note: currency symbol is not yet dynamic.
	 */
	const fetchPrice = async () => {
		const currentProductPrice: any = await productPrice(parentProductId);
		const [productPurchasePrice] =
			currentProductPrice?.productTierDetails?.filter((product: any) => product.transactionTypeId === PurchaseTransactionType.Purchase) ?? [];
		const [productRentPrice] =
			currentProductPrice?.productTierDetails?.filter((product: any) => product.transactionTypeId === PurchaseTransactionType.Rent) ?? [];
		const rentCurrencyCode = productRentPrice?.currency;
		const purchaseCurrencyCode = productPurchasePrice?.currency;
		setCurrencySymbol(getCurrencySymbol(rentCurrencyCode) ?? getCurrencySymbol(purchaseCurrencyCode));
		setTextPurchase(`${wording.purchase}${currencySymbol}${productPurchasePrice?.price}`);
		setPurchasePrice(productPurchasePrice?.price);

		if (productRentPrice) {
			setRentPrice(productRentPrice?.price);
			setTextRent(`${wording.rent}${currencySymbol}${productRentPrice?.price}`);
			setHasRent(true);
		}
	};

	const getVam = (result: any) => {
		if (result?.vam) {
			setVamData(result?.vam);
			setNoVamData(false);
			const newMenuItem = menuItem.map((item) => {
				if (item.title === globalAny.language.extras) {
					return { ...item, disabled: false };
				}
				return item;
			});
			setMenuItem(newMenuItem);
			if (result?.vam?.products?.length === 0) {
				const newMenuItem = menuItem.map((item) => {
					if (item.title === globalAny.language.extras) {
						return { ...item, disabled: true };
					}
					return item;
				});
				setMenuItem(newMenuItem);
				setNoVamData(true);
			}
		} else {
			const newMenuItem = menuItem.map((item) => {
				if (item.title === globalAny.language.extras) {
					return { ...item, disabled: true };
				}
				return item;
			});
			setMenuItem(newMenuItem);
			setNoVamData(true);
		}
	};

	const onPreview = (metadata: any) => {
		setMetadata(metadata);

		const crewMember = filterRole(metadata?.crewMembers, "Director") ?? filterRole(metadata?.crew, "Director");
		setDirector(crewMember);

		const portraitImage = metadata.images?.filter((val: any) => val.width === 600 && val.height === 900)?.map((val: any) => val.url)[0];
		setPackshot(portraitImage);
		setRatingReason(metadataTextSeparator(metadata));
		setOwnProduct(false);
		setIsRedeem(false);
		setIsPurchase(false);
		setAvailabilityRent(false);
		setAvailabilityPurchase(false);
		setAvailabilityRedeem(false);
		setSelectedProductRedeemed({ owned: true });
		getVam({});
	};

	const getMetadata = async (result: any) => {
		if (result?.metadata) {
			if (preview) {
				onPreview(result?.metadata);
				return;
			}

			await onAvailability(result);
			setMetadata(result?.metadata);
			progressVideo(result?.metadata.duration);

			const crewMember = filterRole(result?.metadata?.crewMembers, "Director") ?? filterRole(result?.metadata?.crew, "Director");
			setDirector(crewMember);

			const portraitImage = result?.metadata.images
				?.filter((val: any) => val.width === 600 && val.height === 900)
				?.map((val: any) => val.url)[0];
			setPackshot(portraitImage);
			setRatingReason(metadataTextSeparator(result?.metadata));
		}
	};

	const getOwnedStatus = async (result: any) => {
		if (result?.ownedstatus) {
			const owned = await result?.ownedstatus?.filter((val: any) => val.owned === true).sort((a: any, b: any) => a - b);
			fetchAudioLanguages(owned);

			if (owned?.length === 0) {
				setSelectedProductRedeemed(result?.ownedstatus);
				setProgressBar(0);
			} else {
				setOwnedTransaction(owned.find((val: any) => val.owned === true));
				const hasRented = owned?.find((val: any) => val.transactionType === TRANSACTION_TYPES.TVOD && val.owned === true);
				const hasPurchased = owned?.find((val: any) => val.transactionType !== TRANSACTION_TYPES.TVOD && val.owned === true);
				setIsRented(hasRented);
				setSelectedProductRedeemed(result?.ownedstatus);
				setOwnProduct(true);
				setIsRedeem(hasRented ? false : true);
				setIsPurchase(hasRented ? false : true);
				setIsRent(!hasPurchased && hasRented);
				if (!hasPurchased && hasRented) {
					const videoQuality = getVideoQualityByDeviceHeight(dimensions.screen.height);
					const expiryDetails: any = await getPlaybackWindow(parentProductId, TRANSACTION_TYPES.TVOD, videoQuality);
					expiryDetails?.utcExpiryDate ? setExpiryDate(expiryDetails) : setIsRent(false);
				}
			}
		}
	};

	const filterRole = (data: any, role: string) => {
		const newRole = data?.filter((val: any) => val.role === role).map((val: any) => val.name);
		return newRole;
	};

	const checkNetwork = async () => {
		const isConnected = await onCheckingNetwork();

		if (!isConnected || isConnected === undefined) {
			setVisible(true);
			setToastMessage(globalAny.language.network_connection_lost);
			setLabelText("");
		}
	};

	const onAvailability = async (result: any) => {
		const availability: any = await getAvailability(parentProductId);
		const hasEST = availability?.transactionTypes?.indexOf(TRANSACTION_TYPES.EST) > -1;
		const hasTVOD = availability?.transactionTypes?.indexOf(TRANSACTION_TYPES.TVOD) > -1;
		const isFree = availability?.transactionTypes?.indexOf(TRANSACTION_TYPES.FVOD) > -1;
		let accountDetails = await CacheStorageUtils.getCacheItem(AsyncStorageKeys.accountDetails, true);
		const [availabilityTransactionType] = availability?.transactionTypes ?? [TRANSACTION_TYPES.EST];

		setAvailableTransactionType(availabilityTransactionType);

		if (!accountDetails) {
			const promiseResult = await Promise.allSettled([accountCredits(), accountCreditsExpiration(), accountSubscription(), purchaseHistory()]);
			// @ts-ignore
			const [userCreditsData, userCreditsExpirationData, userSubscriptionData, purchaseHistoryData] = promiseResult.map((i) => i.value);

			CacheStorageUtils.setCacheItem(
				AsyncStorageKeys.accountDetails,
				[userCreditsData, userCreditsExpirationData, userSubscriptionData, purchaseHistoryData],
				true
			);

			accountDetails = await CacheStorageUtils.getCacheItem(AsyncStorageKeys.accountDetails, true);
		}

		const [userCredits] = accountDetails;
		const hasAvailableCredit = userCredits.creditsAvailable > 0;
		let hasRedeemed: boolean = false,
			hasRented: boolean = false,
			hasPurchased: boolean = false,
			hasSubscribed: boolean;

		const isOwned = () => {
			return isFree || hasSubscribed || hasRedeemed || hasRented || hasPurchased;
		};

		if (result?.ownedstatus) {
			const owned = result?.ownedstatus?.filter((val: any) => val.owned === true);
			hasSubscribed = owned?.filter((val: any) => val.transactionType === TRANSACTION_TYPES.SVOD && val.owned).length > 0;
			hasRedeemed = owned?.filter((val: any) => val.transactionType === TRANSACTION_TYPES.EST && val.owned).length > 0;
			hasRented = owned?.filter((val: any) => val.transactionType === TRANSACTION_TYPES.TVOD && val.owned).length > 0;
			hasPurchased = owned?.filter((val: any) => val.transactionType === TRANSACTION_TYPES.EST && val.owned).length > 0;
		}

		if (isOwned()) {
			setOwnProduct(true);

			if (hasRedeemed || hasPurchased) {
				setAvailabilityRedeem(false);
				setIsShowOptions(false);
				setIsRedeem(true);
				setIsPurchase(true);
				return;
			}

			if (hasRented && !hasAvailableCredit) {
				setIsShowOptions(true);
			}

			if (availability?.isRedeemable && hasRented && hasAvailableCredit) {
				setAvailabilityRedeem(true);
			}

			return;
		}

		if (availability?.releaseType === 2) {
			setAvailabilityRent(hasTVOD);
			setAvailabilityPurchase(hasEST);
			setAvailabilityRedeem(false);
			return;
		}

		setHasCredit(hasAvailableCredit);

		if (availability?.releaseType === 0 || !availability?.releaseType) {
			if (hasAvailableCredit) {
				setAvailabilityRedeem(true);
				setAvailabilityRent(hasTVOD);
				setAvailabilityPurchase(false);
				return;
			}

			setAvailabilityRedeem(false);
			setAvailabilityRent(hasTVOD);
			setAvailabilityPurchase(hasEST);
		}
	};

	const progressVideo = async (duration: any) => {
		try {
			const result: any = await getProgressVideo(parentProductId);
			if (result?.responseCode === responseOk) {
				const streamProgress: any = (Number(result?.streamProgress) / (convertDuration(duration) * 1000)) * 100 || 0;
				setIsRenderHome(true);
				setStreamProgress(result?.streamProgress);
				setProgressBar(streamProgress);
				setIsLoadingButton(false);
			}
		} catch {
			setIsLoadingButton(false);
		}
	};

	const getImages = async () => {
		const result = movieStoreValue.titleDetails.titles[parentProductId];
		let TBG = result?.images.tbg;

		try {
			// @ts-ignore
			let imageToUse = TBG;
			if (imageToUse) {
				setBackgroundImage(`${imageToUse.imageUrl}?width=1600&disablewebp=false`);
			} else {
				setBackgroundImage(NO_IMAGE_RETURNED);
			}
		} catch (err) {
			AppLogger.log(err);
		}
	};

	const exitPreview = () => {
		navigation.navigate(routeLogin);
	};

	const onPlay = async () => {
		await checkNetwork();
		AppLogger.log("ownedTransaction", ownedTransaction);
		const streamLimit: any = await getConcurrency(ownedTransaction?.transactionType ?? 1);

		if (!streamLimit?.globalStreams || streamLimit?.streamsAvailable === 0) {
			setType(ComponentTypeEnum.Secondary);
			setToastMessage(wording.concurrentLimit);
			setLabelText(globalAny.language.ok);
			setVisible(true);
			return;
		}

		const playSessionId = uuidv4();
		setPlaySessionId(playSessionId);
		localStorage.setItem(AsyncStorageKeys.playSessionId, playSessionId);
		await postConcurrency(ownedTransaction?.transactionType ?? 1);
		navigation.navigate(routePlayer, { parentProductId, resumeProgress: streamProgress ?? 0 });
	};

	const onTrailer = async () => {
		await checkNetwork();

		const trailer: any = await getTrailer(parentProductId);
		const streamUrl = trailer.url;

		navigation.navigate(routeTrailer, preview ? { parentProductId, streamUrl, preview: true } : { parentProductId, streamUrl });
	};

	const onAssignScreen = () => {
		if (channelData.length > 1) {
			setVamDetails(null);
			navigation.navigate(routeScreenManager, { location: Pages.assignScreen, parentProductId: parentProductId, redirectTo: direction.main });
			return;
		}
		setType(ComponentTypeEnum.Secondary);
		setVisible(true);
		setToastMessage(globalAny.language.assign_single_screen_message);
		setLabelText("");
	};

	const onClickRedeemMovies = () => {
		setShowRedeemModal(true);
	};

	const onClickPurchaseOptions = async () => {
		try {
			const allowUntil = await AsyncStorage.getItem(AsyncStorageKeys.allowUntil);
			const currentTime = new Date().getTime();

			if (allowUntil && parseInt(allowUntil) > currentTime) {
				setToastMessage(globalAny.language.please_try_again_after);
				setType(ComponentTypeEnum.Secondary);
				setVisible(true);
				return;
			}

			setShowPurchaseModal(true);
		} catch (error) {
			AppLogger.error(error);
		}
	};

	const onClose = () => {
		navigation.navigate(preview ? routeLogin : routeHome);
		setNavigateEvent("");
		setSelectedTabs(0);
	};

	const redeemError = () => {
		if (mistakeCount < 3) {
			setType(ComponentTypeEnum.Secondary);
			setShowRedeemModal(false);
			setVisible(true);
			setToastMessage(wording.errorPurchase);
			setLabelText(globalAny.language.label_retry);
			setMistakeCount(mistakeCount + 1);
			return;
		}
		setLabelText("");
		setType(ComponentTypeEnum.Secondary);
		const allowUntil = new Date().setMinutes(new Date().getMinutes() + 1);
		AsyncStorage.setItem(AsyncStorageKeys.allowUntil, allowUntil.toString());
		setToastMessage(wording.redeemLimit);
		setVisible(true);
		setShowRedeemModal(false);
	};

	const onRedeemMovies = async () => {
		setIsProcessing(true);
		const result: any = await redeemProductV8(parentProductId, 6);
		if (result?.responseCode === 10006) {
			setIsRedeem(true);
			const details: any = await CacheStorageUtils.getCacheItem("titledetails_" + parentProductId, true);

			if (details) {
				const newOwnedStatus = details["ownedstatus"].map((item: any) => {
					return { ...item, owned: true };
				});

				const newDetails = {
					images: details["images"],
					metadata: details["metadata"],
					ownedstatus: newOwnedStatus,
					vam: details["vam"],
				};

				await CacheStorageUtils.setCacheItem("titledetails_" + parentProductId, newDetails, true);
				setSelectedProductRedeemed(newOwnedStatus);
			}

			const promiseResult = await Promise.allSettled([accountCredits(), accountCreditsExpiration(), accountSubscription(), purchaseHistory()]);
			// @ts-ignore
			const [userCreditsData, userCreditsExpirationData, userSubscriptionData, purchaseHistoryData] = promiseResult.map((i) => i.value);

			CacheStorageUtils.setCacheItem(
				AsyncStorageKeys.accountDetails,
				[userCreditsData, userCreditsExpirationData, userSubscriptionData, purchaseHistoryData],
				true
			);

			setIsRent(false);
			setIsProcessing(false);
			setShowRedeemModal(false);
			setOwnProduct(true);
			setShowRedeemModal(false);
			setType(ComponentTypeEnum.Primary);
			setToastMessage(wording.redeemSuccess.replace("%d", userCreditsData?.creditsAvailable));
			setLabelText("VIEW DETAILS");
			setVisible(true);
			setIsRenderRedeem(true);
			fetchTitleDetails(parentProductId, true);
			return;
		}

		setIsProcessing(false);
		redeemError();
	};

	const onPurchaseMovie = () => {
		setSelectedTabs(0);
		setShowPurchaseModal(false);
		navigationPayment.navigate(routePayment, { parentProductId, paymentType: "purchase" });
	};

	const onRentMovie = () => {
		setSelectedTabs(0);
		setShowPurchaseModal(false);
		navigationPayment.navigate(routePayment, { parentProductId, paymentType: "rent" });
	};

	const navigateWithEvent = () => {
		if (navigateEvent === Pages.page) {
			navigation.navigate(routePage, { uniqueId });
			return;
		}
		if (navigateEvent === Pages.search) {
			if (lastSearchData.length > 0) {
				navigation.navigate(routeSearchScreen);
			} else {
				setSelectedTabs(0);
				navigation.navigate(routeSearchScreen);
				setNavigateEvent("");
			}
			return;
		}
		if (navigateEvent === Pages.transactions) {
			setSelectedTabs(0);
			navigation.navigate(routeDataManagementSettings, { location: Pages.transactions });
			setNavigateEvent("");
			return;
		}
		if (navigateEvent === Pages.library) {
			setSelectedTabs(0);
			navigation.navigate(routeMyLibrary);
			setNavigateEvent("");
			return;
		}
		if (navigateEvent === Pages.homeAggregatePage) {
			navigation.navigate(routeAggregatePage, { id: aggregatePageId.id, location: aggregatePageId.location });
			setSelectedTabs(0);
			return;
		}
		setSelectedTabs(0);
		navigation.navigate(routeNewCuration);
		setNavigateEvent("");
	};

	const getPreviousRouteName = () => {
		const previousRoute = navigation.getState()?.routes?.[navigation.getState()?.index - 1];
		return previousRoute?.name;
	};

	const back = () => {
		const previousRoute = getPreviousRouteName();
		const isPlayer = previousRoute === routePlayer || previousRoute === routeVamPlayer || !previousRoute;
		setVisible(false);
		setIsSuccess(false, false);

		if (navigation.canGoBack() && !isPlayer) {
			navigation.goBack();
			return;
		}

		if (preview) {
			navigation.navigate(routePage, { uniqueId: "preview" });
			return;
		}

		if (!navigateEvent || navigateEvent === Pages.main) {
			setSelectedTabs(0);
			setNavigateEvent("");
			navigation.navigate(routeHome);
			return;
		}

		navigateWithEvent();
	};

	const dismissRedeemModal = () => {
		setShowRedeemModal(false);
	};

	const dismissPurchaseModal = () => {
		setShowPurchaseModal(false);
	};

	const onPressToast = (command: string) => {
		if (command === globalAny.language.label_view_details) {
			checkCacheData();
		} else if (command === globalAny.language.label_retry) {
			setVisible(true);
			onRedeemMovies();
		}
	};

	const checkCacheData = async () => {
		const transactionHistory: any = await purchaseHistory();
		if (transactionHistory.responseCode === responseOk) {
			navigation.navigate(routeLegalsScreen, { location: Pages.transactions });
			return;
		}

		setType(ComponentTypeEnum.Secondary);
		setToastMessage(globalAny.language.no_transaction_history);
		setVisible(true);
	};

	const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }: any) => {
		const paddingBottom = 100;
		return layoutMeasurement.height + contentOffset.y >= contentSize.height - paddingBottom;
	};

	const calculateGridItemsByWindowWidth = (width: any) => {
		if (width <= 619) return 3;
		return 4;
	};

	const onLazyLoading = () => {
		if (next >= vamData.products.length) return;

		setExtraLoader(true);
		setTimeout(() => {
			setExtraLoader(false);
			setNext(next + gridSize);
		}, 1000);
	};

	const onController = () => {
		if (id === 0) {
			setController(controllerData[1]);
			setId(1);
			return;
		}
		setController(controllerData[0]);
		setId(0);
	};

	const fetchAudioLanguages = async (ownedStatus: any) => {
		const availableLanguages: any = await getAvailableAudioLanguages(
			parentProductId,
			1080,
			ownedStatus[0]?.transactionType ?? availableTransactionType
		);
		if (availableLanguages.responseCode === responseOk) {
			const audio = convertToLanguage(availableLanguages.audioLanguages);
			const subtitle = convertToLanguage(availableLanguages.subtitleLanguages);
			setAudioLang(audio);
			setSubtitleLang(subtitle);
			setMovieAvailableLang(
				`${globalAny.language.available_audio_language} ${audio}<br> ${globalAny.language.available_closed_caption_language} ${subtitle}`
			);
		}
	};

	const checkAvailableTrailer = async () => {
		const trailer: any = await getTrailer(parentProductId);
		const trailerUrl = trailer.url;
		if (trailerUrl && !isCoPassenger()) {
			setIsTrailer(true);
			return;
		}

		setIsTrailer(false);
	};

	const init = async () => {
		try {
			preview ? fetchTitleDetailsPreview(parentProductId, true) : fetchTitleDetails(parentProductId, true);
			await fetchLegals(AsyncStorageKeys.legals);
		} catch (err) {
			AppLogger.log(err);
		}
	};

	const getModalMessage = () => {
		if ((availabilityPurchase && availabilityRent) || (isRent && isPurchase)) return wording.purchaseRentContent;

		if ((!hasCredit && isRent) || availabilityPurchase) return wording.purchaseOnlyContent;

		if (availabilityRent || isRent) return wording.rentOnlyContent;

		return "";
	};

	const onPressTermsAndService = () => {
		navigation.navigate(routeLegalsScreen, { location: Pages.legalTerms });
	};

	const onCheckTitle = () => {
		return renderHeaderTitle(metadata?.title);
	};

	//#endregion

	//#region useEffect
	useEffect(() => {
		if (!mqttDevices.length) {
			return;
		}

		setChannelData(mqttDevices);
	}, [mqttDevices]);

	useEffect(() => {
		init();
		checkAvailableTrailer();
	}, []);

	useEffect(() => {
		setShowCarToast(carActivity === CarActivity.INMOTION);
		setMenuItem(menuList);
	}, [carActivity]);

	useEffect(() => {
		if (preview) {
			setMenuItem(menuListPreview);
			return;
		}
		setMenuItem(menuList);
	}, [preview]);

	useEffect(() => {
		(async () => {
			const result = await movieStoreValue.titleDetails.titles[parentProductId];
			setIsLoadingButton(true);
			if (!preview) await fetchPrice();

			if (result?.metadata?.responseCode === 40058 || isNaN(parentProductId)) {
				setVisible(true);
				setToastMessage(wording.movieNotAvailable);
				setTimeout(() => {
					setSelectedTabs(0);
					location.replace("/landing");
				}, 3000);
				return;
			}

			if (result?.images) {
				getImages();
			}

			getMetadata(result);

			if (!preview) {
				const promiseResult = await Promise.allSettled([
					accountCredits(),
					accountCreditsExpiration(),
					accountSubscription(),
					purchaseHistory(),
				]);
				// @ts-ignore
				const [userCreditsData, userCreditsExpirationData, userSubscriptionData, purchaseHistoryData] = promiseResult.map((i) => i.value);

				CacheStorageUtils.setCacheItem(
					AsyncStorageKeys.accountDetails,
					[userCreditsData, userCreditsExpirationData, userSubscriptionData, purchaseHistoryData],
					true
				);

				getVam(result);
				getOwnedStatus(result);
			}
		})();
	}, [route.params, movieStoreValue, isRenderTDP]);

	useEffect(() => {
		if (isSuccess.result) {
			setType(ComponentTypeEnum.Primary);
			setLabelText(globalAny.language.label_view_details);

			if (isSuccess.isRented) {
				setToastMessage(globalAny.language.movie_rented);
			} else {
				setIsShowOptions(false);
				setIsRenderRedeem(true);
				setIsPurchase(true);
				setToastMessage(globalAny.language.movie_purchased);
			}

			setVisible(true);
			setTimeout(() => {
				setIsSuccess(false, false);
			}, 6000);
		} else {
			setVisible(false);
		}
	}, [isSuccess]);

	/**
	 * useEffect to detect resize on window
	 */
	useEffect(() => {
		const resizeHandler = () => {
			setWindowWidth(Dimensions.get("window").width);
			setGridSize(calculateGridItemsByWindowWidth(Dimensions.get("window").width));
		};
		resizeHandler();

		window.addEventListener("resize", resizeHandler);
		return () => window.removeEventListener("resize", resizeHandler);
	}, [windowWidth]);
	//#endregion

	const renderHeaderTitle = (title: any) => {
		if (!title) return;
		const titleLength: any = metadata?.title.length;
		const longTitle = titleLength > 50;
		return (
			/* @ts-ignores */
			<Text onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
				{longTitle && isHovered ? (
					<StyledMarquee hover={isHovered}>
						<TouchableHighlight onPressIn={() => setIsHovered(true)} onPressOut={() => setIsHovered(false)}>
							<Marquee loop={0} speed={50} direction={"left"}>
								{title}
							</Marquee>
						</TouchableHighlight>
					</StyledMarquee>
				) : (
					title
				)}
			</Text>
		);
	};

	return (
		<SafeAreaView style={{ flex: 1 }}>
			{/* @ts-ignores */}
			<StyledContainer>
				<TopAppBar
					leftIcon={closeIcon}
					leftTitleIcon={backIcon}
					leftIconWidth={92}
					screenTitle={onCheckTitle()}
					onPressLeftIcon={onClose}
					onPressLeftTitleIcon={back}
				/>

				<ScrollView
					onScroll={({ nativeEvent }) => {
						if (isCloseToBottom(nativeEvent) && selectedTabs === 2 && !extraLoader) {
							onLazyLoading();
						}
					}}
					scrollEventThrottle={400}
				>
					{/* @ts-ignores */}
					<TabsContainer>
						{!metadata?.title && <TitleDetailsLoader />}
						{metadata?.title && selectedProductRedeemed && (
							<TabsView tabsToRender={menuItem} paddingLeft={122} disabled={!(metadata && selectedProductRedeemed)}>
								{/* @ts-ignores */}
								<StyledTabsContainer>
									{/* @ts-ignores */}
									<StyledMain bgImage={backgroundImage}>
										<GradientLeftSide />
										{/* @ts-ignores */}
										<StyledContainerColumn>
											<ViewDetails
												metadata={metadata}
												genres={metadata?.genres}
												ratingReason={ratingReason}
												rating={metadata?.rating}
												synopsis={metadata?.synopsis}
												parentProductId={parentProductId}
												redeemed={ownProduct}
												selectedProductRedeemed={selectedProductRedeemed}
												streamProgress={progressBar}
												onPlay={() => onPlay()}
												onTrailer={() => onTrailer()}
												onAssignScreen={() => onAssignScreen()}
												onRedeemMovie={() => onClickRedeemMovies()}
												onPurchase={() => onClickPurchaseOptions()}
												hasCredit={hasCredit}
												isRent={isRent}
												isRented={isRented}
												isRedeem={isRedeem}
												isPurchase={isPurchase}
												expiryDate={expiryDate}
												availabilityRedeem={availabilityRedeem}
												availabilityPurchase={availabilityPurchase}
												availabilityRent={availabilityRent}
												purchaseText={textPurchase}
												rentText={textRent}
												isTrailer={isTrailer}
												isLoadingButton={isLoadingButton}
												isShowOptions={isShowOptions}
												isPreview={preview}
											/>
											{/* @ts-ignores */}
											<StyledImage>
												{!backgroundImage && (
													<>
														{/* @ts-ignores */}
														<StyledLoading>
															<ImgProgressBar />
														</StyledLoading>
														{/* @ts-ignores */}
														<StyledPackshot>
															<Image
																source={{ uri: packshot }}
																width={"100%"}
																height={"100%"}
																style={{ borderRadius: 16, position: "absolute" }}
															/>
														</StyledPackshot>
													</>
												)}
											</StyledImage>
										</StyledContainerColumn>
									</StyledMain>
								</StyledTabsContainer>
								{/* @ts-ignores */}
								<StyledTabsContainer>
									<Details
										genres={metadata?.genres}
										ratingReason={ratingReason}
										rating={metadata?.rating}
										cast={metadata?.cast}
										synopsis={metadata?.synopsis}
										parentProductId={parentProductId}
										copyright={metadata?.copyright}
										audioLanguage={metadata?.language}
										director={director}
										audioLang={audioLang}
										subtitle={subtitleLang}
									/>
								</StyledTabsContainer>
								{/* @ts-ignores */}
								<StyledTabsContainer>
									<Extras
										data={vamData?.products?.slice(0, next)}
										parentProductId={parentProductId}
										redeem={ownProduct}
										ribbonTitle={globalAny.language.assign_single_screen_message}
										id={id}
										extraLoader={extraLoader}
										gridSize={gridSize}
									/>
								</StyledTabsContainer>
							</TabsView>
						)}
					</TabsContainer>
				</ScrollView>

				{channelData && selectedTabs === 2 && isToggleMQTT && (
					<ControllerContent
						data={controller}
						isTitle={channelData?.length <= 1}
						ribbonTitle={globalAny.language.assign_single_screen_message}
						onPress={() => onController()}
					/>
				)}
			</StyledContainer>

			{showRedeemModal && (
				<CustomizeDialogBox
					Header={metadata?.title}
					Body={wording.redeemContent}
					Footer={movieAvailableLang}
					ButtonTextLeft={wording.redeemCredit}
					ButtonTextRight={isProcessing ? wording.processing : wording.cancel}
					legalTextTitle={wording.legalText}
					legalButtonTextTitle={globalAny.language.terms_of_service}
					SingleButton={!!isProcessing}
					CloseButton={false}
					isLegalTerm={isProcessing || !isDefaultTerritory ? false : true}
					Width={680}
					Height={"auto"}
					TypeButtonColorLeft={ComponentTypeEnum.Tertiary}
					TypeButtonColorRight={isProcessing ? ComponentTypeEnum.Tertiary : ComponentTypeEnum.Secondary}
					isProcessing={!!isProcessing}
					onPressRight={isProcessing ? () => {} : dismissRedeemModal}
					onPressTermsAndService={() => onPressTermsAndService()}
					onPressLeft={onRedeemMovies}
				/>
			)}

			{showPurchaseModal && (
				<CustomizeDialogBox
					Header={metadata?.title}
					Body={getModalMessage()}
					Footer={movieAvailableLang}
					ButtonTextLeft={textPurchase}
					ButtonTextMiddle={hasRent ? textRent : textPurchase}
					ButtonTextRight={isProcessing ? wording.processing : wording.cancel}
					ButtonSubTextLeft={wording.rentExpiry}
					legalTextTitle={wording.legalText}
					legalButtonTextTitle={globalAny.language.terms_of_service}
					hasSubTextLeft={hasRent}
					SingleButton={!!isProcessing}
					isProcessing={!!isProcessing}
					HasMiddleButton={!availabilityRent || isRent ? false : availabilityRent}
					HasFirstButton={(!hasCredit && isRent) || availabilityPurchase}
					isTDP={true}
					isLegalTerm={isProcessing || !isDefaultTerritory ? false : true}
					CloseButton={false}
					Width={680}
					Height={"auto"}
					TypeButtonColorLeft={ComponentTypeEnum.Primary}
					TypeButtonColorMiddle={ComponentTypeEnum.Primary}
					TypeButtonColorRight={ComponentTypeEnum.Secondary}
					onPressLeft={onPurchaseMovie}
					onPressMiddle={hasRent ? onRentMovie : onPurchaseMovie}
					onPressRight={isProcessing ? () => {} : dismissPurchaseModal}
					onPressTermsAndService={() => onPressTermsAndService()}
				/>
			)}
			<Toast
				visible={visible}
				text={toastMessage}
				label={labelText}
				type={type}
				onDismissSnackBar={onDismissSnackBar}
				onPress={() => onPressToast(labelText)}
			/>

			<CarActivityDialog
				title={globalAny.language.car_in_motion_title}
				body={globalAny.language.car_in_motion_body}
				buttonText={globalAny.language.car_in_motion_back_to_movie}
				onPress={() => setSelectedTabs(0)}
				show={carActivity === CarActivity.INMOTION && selectedTabs === 1}
			/>

			<Toast
				visible={showCarToast && selectedTabs !== 1}
				text={globalAny.language.car_in_motion_body}
				label={labelText}
				type={type}
				onDismissSnackBar={() => setShowCarToast(false)}
				onPress={() => onPressToast(labelText)}
			/>

			{preview && metadata?.title ? <ExitPreviewButton onPress={() => exitPreview()} /> : <></>}
		</SafeAreaView>
	);
};

export default NewTitleDetails;
